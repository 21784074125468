import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import logo from "../assets/Eli_S_Logos.png";
import menuIcon from "../assets/menu-icon.png";
import Menu from "./menu";

const Header = () => {
    const [showMenu, toggleShowMenu] = useState(false);
    const links = [
        {path: "/about", text: "About"},
        {path: "/portfolio", text: "Portfolio"},
        {path: "/contact", text: "Contact"}
    ];

    return (
        <header>
            <NavLink to="/"><img src={logo} alt="logo"/></NavLink>
            <nav>
                <ul className="nav-links">
                    {links.map((link, i) =>
                        <li key={i}><NavLink to={link.path} activeclassname="active">{link.text}</NavLink></li>
                    )}
                </ul>
            </nav>
            <img src={menuIcon} alt="menu icon" className="menu-icon" onClick={() => toggleShowMenu(!showMenu)}/>
            {(showMenu) &&
                <Menu links={links}/>
            }
        </header>
    );
};

export default Header;
