import React from "react";

const Page404 = () => {
    return(
        <section className="page404">
            <h1>Error 404</h1>
            <p>This is not the page you're looking for...</p>
        </section>
    );
};

export default Page404;