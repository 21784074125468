import React, {useEffect, useState} from 'react';
import  { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Project from "./project";

const ProjectSection = ({type, sectionTitle, initialExpanded = true}) => {
    const [isExpanded, updateIsExpanded] = useState(initialExpanded);
    const [projects, updateProjects] = useState([]);
    const db = getFirestore();
    const projectsCollection = collection(db, "projects");

    //Grabs the Firestore JSON of 'projects' collection within the project.
    const getFirebaseProjects = async () => {
        const q = query(projectsCollection, where("type", "==", type));
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                let firebaseProjects = [];
                querySnapshot.docs.forEach((documentSnapshot) => {
                    firebaseProjects.push(documentSnapshot.data());
                });
                updateProjects(firebaseProjects);
            } else {
                console.error("Query returned nothing");
            }
        } catch (error) {
            console.error("Error getting list: ", error);
        }
    };

    useEffect(() => {
        getFirebaseProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderProjectList = () => {
        if (isExpanded) {
            return <div className="card-list">
                {projects.map((project, index) => {
                    return <Project
                        key={index}
                        title={project.title}
                        image={project.image}
                        imageDescription={project.imageDescription}
                        description={project.description}
                    />
                })}
            </div>
        } else {
            return null;
        }
    };

    const expandButton = isExpanded ? "\u25BC" : "\u25B2";

    return (
        <React.Fragment>
            <h3 onClick={() => updateIsExpanded(!isExpanded)} style={{cursor: "pointer"}}>
                {sectionTitle}
                <span onClick={() => updateIsExpanded(!isExpanded)}>{expandButton}</span>
            </h3>
            {renderProjectList()}
        </React.Fragment>
    );
};

export default ProjectSection;
