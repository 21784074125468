import React from 'react';
import {Route, Routes} from 'react-router-dom';
import firebase from 'firebase/compat/app';
import AboutPage from "./pages/aboutpage";
import ContactPage from "./pages/contactpage";
import PortfolioPage from "./pages/portfoliopage";
import HomePage from "./pages/homepage";
import Header from "./components/header";
import Page404 from "./pages/page404";
import './styles/main.scss';
import FirebaseConfig from "./firebase.config"
// Initialize Firebase
firebase.initializeApp(FirebaseConfig);

const App = () => {
    return (
        <main>
            <Header />
            <section>
                <Routes>
                    <Route path="/" element={<HomePage />}/>
                    <Route path="/about" element={<AboutPage />}/>
                    <Route path="/contact" element={<ContactPage />}/>
                    <Route path="/portfolio" element={<PortfolioPage />}/>
                    <Route path="/404" element={<Page404 />}/>
                    <Route path='*' element={<Page404 />} />
                </Routes>
            </section>
        </main>
    );
};

export default App;
