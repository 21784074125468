import React from 'react';

const Project = ({image, imageDescription, title, description}) => {
    return(
        <article>
            <img src={image} alt={imageDescription}/>
            <div>
                <h3 className="title">{title}</h3>
                <p className="description">{description}</p>
            </div>
        </article>
    );
};

export default Project;
