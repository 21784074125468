import React from 'react';
import {Link} from 'react-router-dom';
import ReactHelmet from "react-helmet";
import ProfileImage from "../assets/ProfilePhoto.jpg";
import SmallProfileImage from "../assets/ProfilePhotoSmall.png";
import CSSLogo from "../assets/Icons/CssLogo.svg";
import ReactLogo from "../assets/Icons/ReactLogo.svg";
import TypeScriptLogo from "../assets/Icons/TypeScriptLogo.svg"
import SwiftLogo from "../assets/Icons/SwiftLogo.svg"
import SQLLogo from "../assets/Icons/SqlLogo.svg";

const icons = [ReactLogo, TypeScriptLogo, SwiftLogo, CSSLogo, SQLLogo];

const AboutPage = () => {
    return(
        <section className="about">
            <ReactHelmet>
                <title>About</title>
            </ReactHelmet>
            <article className="background">
                <picture>
                    <img src={ProfileImage} title="Eli Sokeland" alt="Eli Sokeland"/>
                    <img src={SmallProfileImage} title="Eli Sokeland" alt="Eli Sokeland" id="small-profile-photo"/>
                </picture>
                <div>
                    <h2>Eli Sokeland</h2>
                    <p>
                        I am a software engineer at SportRadar. I currently work with fullstack TypeScript applications. <a href="https://sportradar.com/?lang=en-us">SportRadar</a> is 
                        a software company that specializes in sport data information.
                    </p>
                    <Link to="/contact">Contact Me</Link>
                </div>
            </article>
            <article>
                <h3>Skills</h3>
                <div className="icon-list">
                    {icons.map((icon, i) => {
                        return <img src={icon} key={i} className="icon" alt="icon"/>
                    })
                    }
                </div>
            </article>
        </section>
    );
};
export default AboutPage;