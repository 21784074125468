import React from 'react';
import ProjectSection from "../components/projectSection";

const PortfolioPage = () => {

    return (
        <section className="portfolio">
            <ProjectSection sectionTitle={"Digital Corps"} type={"Digital Corps"}/>
            <ProjectSection sectionTitle={"Personal/School"} type={"personal/school"}/>
        </section>
    )
};

export default PortfolioPage;
