import React from "react";
import {NavLink} from "react-router-dom";

const Menu = ({links}) => {
    return (
        <nav className="mobile-nav-links">
            <ul>
                {links.map((link, i) =>
                    <li key={i}><NavLink to={link.path} activeClassName="active">{link.text}</NavLink></li>
                )}
            </ul>
        </nav>
    );
};

export default Menu;