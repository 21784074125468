//Configuration based off of necessary Firebase information
const firebaseConfig = {
    apiKey: "AIzaSyBK2pJOM57qODZXXBMNY4BgFBmXj6yDigQ",
  authDomain: "portfolio-website-sokeland.firebaseapp.com",
  databaseURL: "https://portfolio-website-sokeland.firebaseio.com",
  projectId: "portfolio-website-sokeland",
  storageBucket: "portfolio-website-sokeland.appspot.com",
  messagingSenderId: "737818456610",
  appId: "1:737818456610:web:27f4b04d5009272d"
};

export default firebaseConfig;
