import React from 'react';
import FacebookImage from "../assets/Icons/FacebookLogo.svg";
import LinkedInImage from "../assets/Icons/LinkedInLogo.svg";
import GitHubImage from "../assets/Icons/GithubLogo.svg";
import ProfilePhoto from "../assets/ProfilePhotoSmall.png";

const facebookURL = "https://www.facebook.com/Eli017";
const linkedInURL = "https://www.linkedin.com/in/eli-sokeland-3287b214a/";
const gitHubURL = "https://github.com/Eli017";

const ContactPage = () => {
    return(
        <section className="contact">
            <article>
                <div className="profile-photo">
                    <img src={ProfilePhoto} alt="Eli Sokeland"/>
                </div>
                <div className="contact-info">
                    <a href="tel:+18126778961">812-677-8961</a>
                    <a href="mailto: elisokeland@yahoo.com">elisokeland@yahoo.com</a>
                    <p>Feel free to message me for any project!</p>
                </div>
                <div className="contact-links">
                    <a href={facebookURL}><img src={FacebookImage} alt="Facebook" title="Facebook"/></a>
                    <a href={linkedInURL}><img src={LinkedInImage} className="socialLink" alt="LinkedIn" title="Facebook"/></a>
                    <a href={gitHubURL}><img src={GitHubImage} alt="GitHub" title="GitHub"/></a>
                </div>
            </article>
        </section>
    );
};
export default ContactPage;